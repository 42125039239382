<template>
  <div class="image-with-floating-text overflow-hidden">
    <div class="mx-auto xl:flex relative">
      <img
        v-if="img"
        class="object-cover w-full"
        :src="img"
        alt=""
      />
      <div
        class="
          xl:absolute
          xl:bg-white
          xl:right-0
          xl:pr-2
          floating-text
          max-w-4xl
          z-10
        "
      >
        <div class="text-box text-center lg:text-left py-8 px-8">
        <h1 v-if="title" class="lg:text-left mb-4">{{ title }}</h1>
          <br />
          <p  v-if="text" v-html="text"></p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: ["title", "text", "img"]
}
</script>
<style scoped>
.floating-text {
  top: 8rem;
}
.image-with-floating-text img {
    height: calc(100vh - 20rem)
}
</style>