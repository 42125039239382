<template>
  <div class="servicio">
    <banner
      :title="servicio.title"
      :image="servicio.header.image"
      :icon="servicio.header.icon"
    />
    <tab-view class="mx-auto" :items="servicio.items" />
    <div class="container mx-auto">
      <Expandables :items="servicio.expandables" />
    </div>
    <div v-if="servicio.imageWithText">
      <ImageWithFloatingText
        :title="servicio.imageWithText.title"
        :text="servicio.imageWithText.text"
        :img="servicio.imageWithText.img"
      />
    </div>
  </div>
</template>
<script>
import { servicios } from "@/vars";
import Banner from "@/components/Banner";
import TabView from "@/components/TabView";
import Expandables from "@/components/Expandables";
import ImageWithFloatingText from "@/components/ImageWithFloatingText";

export default {
  components: {
    Banner,
    TabView,
    Expandables,
    ImageWithFloatingText,
  },
  created() {
    this.servicio =
      servicios.find((s) => s.slug === this.$route.params.slug) || {};
    this.$watch(
      () => this.$route.params,
      (toParams, fromParams) => {
        console.log(`to: ${toParams.slug} from: ${fromParams.slug}`);
        // react to route changes...
        this.servicio = servicios.find((s) => s.slug === toParams.slug) || {};
      }
    );
  },
  setup() {
    return {};
  },
};
</script>