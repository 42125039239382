<template>
  <div
    class="w-full max-w-6xl group px-4 mb-8 mx-auto"
    v-for="(item, index) in items"
    :key="index"
  >
    <Disclosure v-slot="{ open }" :defaultOpen="index == 0">
      <DisclosureButton
        class="
          flex
          justify-center
          items-center
          w-full
          py-4
          px-8
          text-sm
          font-medium
          text-left
          bg-blue-100
          focus:outline-none
          transition
          duration-300
        "
      >
        <span class="text-2xl font-black"> {{ item.title }} </span>
        <ChevronRightIcon
          :class="open ? 'transform -rotate-90' : ''"
          class="h-12 w-12 flex-shrink-0 ml-auto mr-0 text-blue-500"
        />
      </DisclosureButton>
      <transition
        enter-active-class="transition duration-500 ease-out"
        enter-from-class="transform -translate-y-6 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform translate-y-0 opacity-100"
        leave-to-class="transform -translate-y-6 opacity-0"
      >
        <DisclosurePanel
          class="
            pb-8
            px-8
            transition
            whitespace-pre-line
            duration-300
            text-lg
            bg-blue-100
            text-gray-900
            font-serif
            bg-white
          "
        >
          <div
            v-if="typeof item.descr === 'string'"
            :class="item.img && item.float ? 'lg:flex items-center' : ''"
          >
            <img
              v-if="item.img"
              :src="item.img"
              :class="item.float ? 'w-48 float-right lg:float-none lg:mr-8' : 'mx-auto mb-8'"
              alt=""
            />
            <p class="align-top" v-html="item.descr"></p>
          </div>
          <ol v-else class="ml-4 list-decimal font-serif pr-8">
            <li
              class="text-md leading-6 mb-3.5 last:mb-0"
              v-for="(item, idx) in item.descr"
              :key="idx"
            >
              {{ item }}
            </li>
          </ol>
        </DisclosurePanel>
      </transition>
    </Disclosure>
  </div>
</template>
<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronRightIcon,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    return {
      open: false,
    };
  },
};
</script>
<style scoped>
ol {
  counter-reset: item;
}

ol > li {
  list-style-type: none;
  counter-increment: item;
}

ol > li:before {
  display: inline-block;
  width: 1em;
  text-indent: -2em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}
</style>