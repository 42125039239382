<template>
  <div class="w-full max-w-7xl px-8 py-16 sm:px-0">
    <TabGroup>
      <TabList
        ref="tabGroup"
        class="
          flex flex-wrap
          lg:flex-nowrap
          px-8
          justify-center
          lg:px-4
          lg:space-x-4
          rounded-xl
        "
      >
        <Tab
          v-for="(item, index) in Object.keys(items)"
          as="template"
          :key="`${item.slug}-${index}`"
          v-slot="{ selected }"
        >
          <button
            :class="[
              'w-full m-2 max-w-xs py-2.5 text-base font-medium text-white rounded-full',
              'focus:outline-none',
              'font-sans',
              selected ? 'bg-blue-400' : 'bg-gray-400',
            ]"
          >
            {{ item }}
          </button>
        </Tab>
      </TabList>

      <TabPanels class="mt-8">
        <TabPanel
          v-for="(item, index) in Object.values(items)"
          :key="`${item.slug}-${index}`"
          class="bg-white rounded-xl p-2"
        >
          <div class="mx-auto">
            <div class="bg-gray-100">
              <div class="content lg:flex">
                <img
                  class="
                    image-hostelero
                    object-cover
                    overflow-hidden
                    w-full
                    lg:max-w-2xl
                  "
                  :src="item.img"
                  alt=""
                />
                <div class="content-text p-4 lg:p-12 text-center lg:text-left">
                  <h1 class="my-8 text-4xl">{{ item.title }}</h1>
                  <div class="text max-w-2xl leading-loose">
                    <p class="leading-loose mb-8">{{ item.descr }}</p>
                    <ul
                      v-if="item.dots && item.dots.length > 0"
                      class="ml-6 list-disc"
                    >
                      <li v-for="(dot, index) in item.dots" :key="index">
                        <p class="leading-loose">{{ dot }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
// import { onUpdated } from "vue";

export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  updated() {
    console.log(this.$refs.tabGroup);
    this.$refs.tabGroup.selectedIndex = 0;
  },
  setup() {},
  props: {
    items: {
      required: true,
      default: [],
    },
  },
};
</script>

<style scoped>
li {
  color: rgb(96, 165, 250);
}
li p {
  color: black;
}

</style>